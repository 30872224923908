import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthContext } from '../shared/hooks';
import { Box, CircularProgress } from '@mui/material';
import { PnoWrap } from '../components';

export default function ProtectedRoutes() {
  const { user, isLoading, setIsLoading } = useAuthContext()

  React.useEffect(() => {
    if (user !== null) {
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) {
    return <Box width={'100vw'}
      height={'100vh'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <PnoWrap text='INFQE' color={'#E19E06'} size={'6rem'} />
        <CircularProgress color='primary' size={'5rem'} />
      </Box>
    </Box >;
  }

  return (user) ? <Outlet /> : <Navigate to={'/login'} />
}
