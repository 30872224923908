import { Box, Button, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Pagination, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Input, MenuDrawer } from "../../components";
import { Environment, Head } from "../../shared";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../shared/hooks";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export function Access() {
  const [data, setData] = useState([]);
  const { palette } = useTheme()
  const [profile, setProfile] = useState(null);

  const [change, setChange] = useState(false)
  const { addRules, getPermissions, updatePermissionsRules, getPermissionsRules, isLoading } = useAuthContext()
  
  const handleGetRules = async () => {

    const result = await getPermissionsRules()

    if(result instanceof Error) {
      console.log(result);
    }
    
    setData(result.data)
  }
 
  const handleGetPermissions = async () => {

    const result = await getPermissions()

    if(result instanceof Error) {
      console.log(result);
    }

    console.log({result});
  }
  
  useEffect(() => {
    handleGetRules()
    handleGetPermissions();
  }, [change])
  
  const handleSaveProfile = async () => {
    if(!profile) return toast.error('O campo é obrigatório!');

    const result = await addRules({name: profile, permissions: []})

    if(result instanceof Error) {
      toast.error('Problemas ao salvar o perfil')
    }
      toast.success('Perfil salvo com sucesso!')
  }


  const handleChangeRules = async (name, rules_id, permissions_id) => {
    const profile = data[data.findIndex(el => el.id === rules_id)];
    const permissions = []
    
    profile?.permissions.forEach(el => permissions.push(el.id));

    if(permissions.indexOf(permissions_id) !== -1) {
      permissions.splice(permissions.indexOf(permissions_id), 1)
    }else {
      permissions.push(permissions_id)
    }
    
    try {
      await updatePermissionsRules(name, permissions, rules_id)
      setChange(!change);
      toast.success('Perfil actualizado com sucesso!')
    } catch (error) {
      console.log({error});
    }
  }

  return (

    <MenuDrawer page='Controle de acesso'>
      <Head title='Perfil de acesso' />
      <Box component={Paper} p={2} m={2} flex={1}>
        <Box >
          <Box display={'flex'} gap={1} mb={2} mx={1}>
            <Box width={'100%'}>
            <section className='bloco-pesquisa-botao'>
            <div className='search' >
                <FormControl sx={{ minWidth: 250, maxWidth: 250 }} size="small">
                    <InputLabel id="demo-simple-select-label" sx={{ display: 'flex', gap: 1 }}>Perfil</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        size='small'
                        id="demo-simple-select"
                        value={''}
                        label="Perfil"
                        sx={{ py: .5 }}
                    >
                        <MenuItem value={10}>Janeiro</MenuItem>
                        <MenuItem value={20}>Fevereiro</MenuItem>
                        <MenuItem value={30}>Março</MenuItem>
                    </Select>
                </FormControl>
            </div>
              {/* <Box display={'flex'} gap={1}>
                <Typography fontSize={14}>Titulo do perfil</Typography>
                <Typography fontSize={14} color={'error'}>*</Typography>
              </Box>
              <Box>
                <Input 
                  fullWidth 
                  placeholder='Ex: Admin'
                  value={profile??''}
                  onChange={(e) => setProfile(e.target.value)} />
              </Box> */}
              <button 
                className='btn btn-add'                
                onClick={() => handleSaveProfile()}
                disabled={isLoading}
              >
                Adicionar Perfil
              </button>
              </section>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} gap={2} flexWrap={'wrap'} justifyContent={'center'}>
        <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
              <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                  <TableRow>
                    <TableCell className='title-table text-center'>Perfis</TableCell>
                    <TableCell className='title-table text-center'>Criar</TableCell>
                    <TableCell className='title-table text-center'>Visualizar</TableCell>
                    <TableCell className='title-table text-center'>Editar</TableCell>
                    <TableCell className='title-table text-center'>Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[1, 3, 5].length > 0 &&
                    [1, 3, 7].map((item, id) => (
                      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell className='text-center'>Professor</TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                        <TableCell className='text-center'>
                          <Switch {...label} defaultChecked />
                        </TableCell>
                      </TableRow>
                    ))
                  }

                  {false && (
                    <TableRow>
                      <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                    </TableRow>)
                  }

                </TableBody>
                <TableFooter>
                  {data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                      <Pagination
                        count={Math.ceil(1 / Environment.ROW_LIMIT)}
                        page={Number(1)} /* onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })} */
                        color='primary'
                        size='small'
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
          {/* <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Pagina Inicial</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
            </Box>
            </Box>
                   


                
          </Box> */}
{/* 
          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Candidatos Incritos</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Candidatos Selecionados</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Escolas</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Funcionários</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Alunos</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Área de Formação</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>           
          </Box>

          <Box border={1} width={'170px'} borderRadius={4} overflow={'hidden'}>
            <Box bgcolor={palette.primary.main}>
              <Typography align="center">Perfil de acesso</Typography>
            </Box>
            <Box  px={2}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Listar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Visualizar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Criar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Editar</p>
                <Switch {...label} checked={true} />
              </Box>
              <Box  display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <p>Excluir</p>
                <Switch {...label} checked={true} />
              </Box>
            </Box>     */}
          {/* </Box> */}
        </Box>
      {/* </Box> */}

    </MenuDrawer>
  )
}


