import React, { useEffect, useMemo, useState } from 'react'
import { Input, MenuDrawer, PnoWrap } from '../../components'
import { Environment, GET_DISCIPLINE, Head, LocationService, courseService } from '../../shared'
import {
    Box, Button, Checkbox, Drawer, FormControl, Icon, IconButton, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Pagination,
    Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,
    Typography, useTheme
} from '@mui/material'
import * as yup from 'yup'

import { useSearchParams, Link } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'


const schema = yup.object({
    training_area_id: yup.number().required().moreThan(0),
    number_vacancies: yup.number().required().moreThan(0)
})

export function PublishVaga() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [idVacancies, setIdVacancies] = React.useState(null);
    const [areaFormation, setAreaFormation] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });

    const { token } = useAuthContext()
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [Change, setChange] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openDrawer1, setOpenDrawer1] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [discipline, setDiscipline] = useState([]);
    const [disciplineTwo, setDisciplineTwo] = useState([]);
    const [Percentage, setPercentage] = useState([]);
    const [idTrainingDiscipline, setIidTrainingDiscipline] = useState(null);

    

    const [checked, setChecked] = React.useState([]);
    const [checkedToRemove, setCheckedToRemove] = React.useState([]);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            training_area_id: undefined,
            number_vacancies: undefined,            
        }
    })

    const search = useMemo(() => {
        return SearchParmams?.get('search') || ''
    }, [SearchParmams])

    const page = useMemo(() => {
        return SearchParmams?.get('page') || 1
    }, [SearchParmams])


    React.useEffect(() => {
        const findAllCandidate = async () => {
            try {
                setLoading(true);
                const response = await LocationService.getAllVagasPublish(search ?? '', token ?? '');
                
                setData(response?.data ?? []);
                setTotalAccount(response?.meta.total)
            } catch (error) {
                if (error.message === 'Failed to fetch')
                    toast.error('Problemas de conexão com a internet!')
                toast.error(error.message)

            } finally {
                setLoading(false);
            }
        }
        findAllCandidate();
        setChange(false)
    }, [search, page, Change]);


    const toggleDrawer = (id = undefined) => {
      setIsUpdate(null)
      setIdVacancies(null)
        if(!id) {
          setOpenDrawer(!openDrawer)
        }else {
          setIsUpdate(id)
          setOpenDrawer(!openDrawer)
          
        }
    }
    const toggleDrawer1 = (id = undefined) => {
        
          setOpenDrawer1(!openDrawer1)
        
    }

    const selectedAF = (id, vacancies) => {
      setValue('number_vacancies', vacancies)
      setIdVacancies(data[data.findIndex(el => el.id === Number(id))].id)
      setValue('training_area_id', data[data.findIndex(el => el.id === Number(id))].training_area_id)
    }

      useEffect(() => {
        const findAllCourses = async () => {
          try {
            setLoading(true);
            const { url, options } = courseService.GET_TRAINIG(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
            const response = await fetch(url, options);
            const result = await response.json();
            
            setAreaFormation(result.data??[]);
          } catch (error) {
            if (error.message === 'Failed to fetch')
              toast.error('Problemas de conexão com a internet!')
            toast.error(error.message)
    
          } finally {
            setLoading(false);
          }
        }
        findAllCourses();
        setChange(false)
      }, []);

      const handleUpdate = async ({training_area_id, number_vacancies}) => {

        try {
            const result = await courseService.put_TRAINING_vacancies({number_vacancies}, idVacancies, token)
        
            toast.success('Vaga Actualizada com sucesso.')
            setIdVacancies(null)
            toggleDrawer()
            setChange(true)
            setError(null)
        } catch (error) {
            console.log({error});
        }
      }

      const handleSubmitData = async (data) => {
            try {
                const result = await courseService.POST_TRAINING_vacancies(data, token)
      
                toast.success('Vaga publicada com sucesso.')
                toggleDrawer()
                setChange(true)
                setError(null)
            } catch (error) {
                console.log({error});
                toast.error(error.message)
            }
      }

      useEffect(() => {
        const findAllDiscipline = async () => {
          try {
            const { url, options } = GET_DISCIPLINE(page, 1000000000000000, '', token);
            const response = await fetch(url, options);
            const result = await response.json();
            setDiscipline(result.data);
            console.log({a: result.data});
            setTotalAccount(result.meta.total)
          } catch (error) {
            // setError(error.message)
            toast.error(error.message)
          }
        }
        findAllDiscipline();
      }, []);

      const handleToggle = (value) => () => {
        console.log('entrei', value, {checked});
        
        let newChecked = [...checked];
        if (newChecked.indexOf(value) === -1) {
          setChecked(newChecked);
          newChecked.push(value);
        } else {
          console.log('entrei\n\ndelete');
          
          newChecked.splice(newChecked.indexOf(value), 1);
          console.log({newChecked});
          setChecked(newChecked);
          
          // setCheckedToRemove(old => [...old, value])
        }
      };

      const getListDiscipline = async(id) => {
        let aux = [];
        setIidTrainingDiscipline(id)
        setDisciplineTwo([])
        try {
          setChecked([])
          const response = await courseService.get_TRAINING_discipline(id, token);
          console.log({response});
          setDisciplineTwo(response?.data[0]?.subject_exam_accesses)
          response?.data[0]?.subject_exam_accesses.forEach(el => {
            aux.push(el?.subject?.id)
            
            // if(discipline.findIndex(el => el.id === el?.subject?.id) !== -1) {
            //   console.log({el});
            //   const d = discipline.map(dis => {
            //     console.log(el?.percentage);
            //     return {...dis, percentage: el?.percentage}
            //   })

            //   console.log({d});
            // }
          });

          setCheckedToRemove(aux)
          setChecked(aux)
          // console.log({checked, aux});
        } catch (error) {
          toast.error(error.message)          
        }
      };

      const handleUpdateDisciplineAreaFormation = async () => {
        try {
          const cleanChecked = checked.filter((el) => {
            if(!checkedToRemove.includes(el)){
              return el;
            }
          } )
          let percentage = new Array(cleanChecked.length);

          cleanChecked.forEach((d, id) => {

            if(Percentage.findIndex((e) => e.id === d.id) !== -1) {
              percentage[id] = Percentage[Percentage.findIndex((e) => e.id === d.id)].id;
            }
          });
          
          console.log({cleanChecked, percentage});

          const data = {
            Training_area_id: idTrainingDiscipline,
            subject_ids: cleanChecked,
            percentage_subject_ids: percentage
          }

          const result = await courseService.POST_TRAINING_discipline(data, token)
          console.log({result});
          
          if(result.message) return toast.error(result.message);
          
          toast.success('disciplinas para os testes actualizadas com sucesso.')
          toggleDrawer1()
          setChange(true)
          setError(null)
        } catch (error) {
          
        }
      }

    return (
        <MenuDrawer page='Vagas'>
        <Head title='Vagas' />
        <Box component={Paper} py={2} m={2} flex={1}>
          <Box m={1} ml={2}>
            <section className='bloco-pesquisa-botao'>
              <div className='search'>
                <input type='text' placeholder='Pesquisar'
                  onChange={(evt) => { setSearchParmams({ search: evt.target.value }, { replace: true }) }}
                  value={search ?? ''} />
                <button><i className='fas fa-search'></i></button>
              </div>
  
              <Button className='btn btn-add' onClick={() => toggleDrawer()}>
                Publicar Vagas  <i className='fas fa-plus ml-1'></i>
              </Button>
            </section>
            <Drawer anchor='right' open={openDrawer} variant='temporary' >  
                <Box width={300} pt={10} px={2} component={'form'} noValidate onSubmit={ !isUpdate ? handleSubmit(handleSubmitData) : handleSubmit(handleUpdate)} >
                    <Box display={'flex'} justifyContent={'space-between'}>
                    {isUpdate ? <PnoWrap color='black' text='Actualizar' />:<PnoWrap color='black' text='Publicar' />}
    
                    <Icon onClick={() => toggleDrawer()}>close</Icon>
                    </Box>
    
                    <Box display={'flex'} flexDirection={'column'} >
                        {/* <FormLabel>Nacionalidade</FormLabel> */}
                        
                        <Controller
                            control={control}
                            name='training_area_id'
                            render={({field: {value, onChange}}) => (
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Selecione o Area de Formação</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={value??''}
                                        label="Selecione o Area de Formação"
                                        onChange={onChange}
                                        fullWidth
                                        sx={{minWidth: 300}}
                                        disabled={!!idVacancies}
                                    >
                                        {areaFormation.length > 0 && areaFormation.map(el => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Box>
                    <Box my={2}>
                        <Controller
                            control={control}
                            name='number_vacancies'
                            render={({field: {value, onChange}}) => (
                                <Input label={'digite o número de vagas'} type={'number'} errorText={errors?.number_vacancies?.message} error={!!errors?.number_vacancies?.message} value={value??''} onChange={onChange}/>
                            )}
                        />
                    </Box>
                    {isUpdate ? <Button variant='contained' type='submit' color='secondary' >
                    update
                    </Button>:
                    <Button variant='contained' type='submit'>
                    Salvar
                    </Button>}
                </Box>
            </Drawer>

            <Drawer anchor='right' open={openDrawer1} variant='temporary' >  
                <Box width={300} pt={10} px={2}  >
                    <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                    <PnoWrap color='black' text='Actualizar dados da área de formação' />
    
                    <Icon onClick={() => toggleDrawer1()}>close</Icon>
                    </Box>
    
                        
                    {discipline.length > 0 && discipline.map((dis, index) => (
                      <Box display={'flex'} justifyContent={'space-between'} key={dis.id} >
                        <Box width={'80%'}>
                          <ListItem disablePadding
                            secondaryAction={<IconButton edge="end" aria-label="comments" />}
                          >
                            <ListItemButton role={undefined} onClick={handleToggle(dis.id)} dense>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(dis.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${dis.id}` }}
                                />
                              </ListItemIcon>
                              <ListItemText id={`checkbox-list-label-${dis.name}`} primary={`${dis.name}`} sx={{ textWrap: 'nowrap', textOverflow: 'ellipsis' }} />
                            </ListItemButton>
                          </ListItem>
                        </Box>
                        <TextField sx={{width: 50}} 
                          placeholder='%' 
                          name={`percentage${index}`} 
                          value={
                            disciplineTwo[disciplineTwo.findIndex(el => dis.id === el?.subject?.id)]?.percentage 
                            ?? Percentage[Percentage.findIndex(el => dis.name === el?.name)]?.value ?? ''}

                          onChange={(e) => {setPercentage(old =>{
                            const updatedValue = e.target.value;                            
                            const index = old.findIndex(el => el.name === dis.name);
                            if (index !== -1) {
                              // Atualizar valor existente
                              const updatedArray = [...old];
                              updatedArray[index] = {
                                ...updatedArray[index],
                                value: updatedValue,
                              };
                              return updatedArray;
                            } else {
                              // Adicionar novo item se não existir
                              return [...old, { id: dis.id, name: dis.name, value: updatedValue }];
                            }
                          })}}
                        />
                      </Box>
                    )
                    )}
                    <Button variant='contained' onClick={() => handleUpdateDisciplineAreaFormation()}>
                      Actualizar
                    </Button>
                </Box>
            </Drawer>

            <Box marginBottom={5}>
              <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                <TableContainer component={Paper} elevation={0} sx={{ flexGrow: 1 }} style={{ border: '1px solid #ededed' }}>
                  <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                    <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                      <TableRow>
                        <TableCell className='title-table text-center'>Área de Formação</TableCell>
                        <TableCell className='title-table text-center'>Total de Vagas</TableCell>
                        <TableCell className='title-table text-center'>Acções</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.length > 0 ?
                        data.map((item) => (
                          <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell className='text-center cel-name'>{item.training_area
.name}</TableCell>
                            <TableCell className='text-center'>
                              {item.number_vacancies
}
                            </TableCell>
                            <TableCell align="center">
                              <Box display={'flex'} gap={1} justifyContent={'center'}>
                                <Typography className='btn-icon btn-edit' onClick={() => {toggleDrawer1(); getListDiscipline(item.id) }}>
                                  <i className="fa-solid fa-eye"></i>
                                </Typography>
                                <Typography className='btn-icon btn-edit' onClick={() => {toggleDrawer(item.id); selectedAF(item.id, item.number_vacancies)}}>
                                  <i className="fa-solid fa-edit"></i>
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )) : (
                          <TableRow>
                            <TableCell colSpan={12} align="center"><Typography width={250}>Lista de Cursos Vazia</Typography></TableCell>
                          </TableRow>
                        )
                      }
  
                      {loading && (
                        <TableRow>
                          <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                        </TableRow>)
                      }
  
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                          <Pagination
                            count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                            page={Number(page)} onChange={(_, newPage) => { setSearchParmams({ _, page: newPage.toString() }, { replace: true }) }}
                            color='primary'
                            size='small'
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </MenuDrawer>
    )
}
