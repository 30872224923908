import { api_services } from "./config";

const getAll = async (page = 1, limit = 15, filter = '', id = undefined, token) => {
    try {
        const url = `/functionary?_page=${page}&_limit=${limit}&_filter=${filter}&_id=${id}`

        const response = await api_services.get(url, {
            method: 'GET',
            headers: { 
                'content-Type': 'application-json',
                Authorization: `Bearer ${token}` 
            },
        });

        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error('problemas ao buscar os funcionarios')
    }
}

const getOne = async (id = undefined, token) => {
    try {
        const response = await api_services.get(`/functionary/${id}`, {
            method: 'GET',
            headers: { 
                'content-Type': 'application-json',
                Authorization: `Bearer ${token}`
            },
        });

        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error('problemas ao buscar o funcionario')
    }
}

const delOne = async (id = undefined) => {
    try {
        const response = await api_services.delete(`/functionary/${id}`);

        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error('problemas ao deletar o funcionario')
    }
}

const post = async (data) => {
    try {
        const response = await api_services.post(`/functionary`, JSON.stringify(data));

        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error('problemas ao salvar o funcionario')
    }
}


export const functionaryServices = {
    getAll,
    getOne,
    delOne,
    post,
}