export const RoutesList = [
    {
        title: 'Página Inicial',
        to: '/dash',
        mainIcon: 'fas fa-house',
        subMenu: undefined
    },
    {
        title: 'Gestão de Usuários',
        to: undefined,
        mainIcon: 'fas fa-users-cog',
        subMenu: [
            {
                title: 'Alunos',
                Icon: 'fas fa-user-graduate',
                to: '/people/students'
            },
            {
                title: 'Cand. Inscritos',
                Icon: 'fas fa-users',
                to: '/people/students/enrolled'
            },
            {
                title: 'Cand. Selecionados',
                Icon: 'fas fa-users',
                to: '/people/students/selected'
            },
            {
                title: 'Funcionários',
                Icon: 'fas fa-user-tie',
                to: '/people/functionary'
            },
            // {
            //     title: 'Resultado',
            //     Icon: 'fas fa-user-tie',
            //     to: '/people/response'
            // },
            {
                title: 'Perfis de Acesso',
                Icon: 'fas fa-user-gear',
                to: '/people/access'
            }
        ]
    },
    {
        title: 'Gestão Escolar',
        to: undefined,
        mainIcon: 'fas fa-school',
        subMenu: [
            {
                title: 'Area de Formação',
                Icon: 'fas fa-school',
                to: '/academic/training'
            },
            {
                title: 'Escolas',
                Icon: 'fas fa-school',
                to: '/academic/school'
            },
            {
                title: 'Cursos',
                Icon: 'fa-solid fa-person-chalkboard',
                to: '/academic/courses'
            },
            {
                title: 'Salas e Turmas',
                Icon: 'fas fa-chalkboard-teacher',
                to: '/academic/classes'
            },
            {
                title: 'Disciplinas',
                Icon: 'fas fa-book',
                to: '/academic/discipline'
            },
            {
                title: 'Grade Curricular',
                Icon: 'fa-solid fa-book-open',
                to: '/'
            },
            {
                title: 'Localização Interna',
                Icon: 'fa-solid fa-map',
                to: '/'
            }
        ]
    },
    {
        title: 'Gestão Pedagógica',
        to: undefined,
        mainIcon: 'fas fa-folder',
        subMenu: [
            {
                title: 'Exame Especial',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            {
                title: 'Exame Recurso',
                Icon: 'fas fa-rectangle-list',
                to: ''
            },
            // {
            //     title: 'Folha de Prova',
            //     Icon: 'fas fa-file',
            //     to: ''
            // },
            {
                title: 'Melhoria de Nota',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Mini Pauta',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Pauta',
                Icon: 'fas fa-file-excel',
                to: '/academic/pauta'
            },
            
        ]
    },
    {
        title: 'Gestão Administrativa',
        to: undefined,
        mainIcon: 'fas fa-user',
        subMenu: [
            {
                title: 'Declaração de Frequência',
                Icon: 'fas fa-file-word',
                to: ''
            },
            {
                title: 'Declaração com Notas',
                Icon: 'fas fa-file-excel',
                to: ''
            },
            {
                title: 'Transferência',
                Icon: 'fas fa-exchange-alt',
                to: ''
            },
            {
                title: 'Certificado',
                Icon: 'fas fa-file',
                to: ''
            },
            {
                title: 'Matrícula',
                Icon: 'fa-solid fa-pen',
                to: '/academic/register'
            },
            {
                title: 'Confirmação de Matrícula',
                Icon: 'fa-solid fa-edit',
                to: '/academic/performance'
            },
            {
                title: 'Termo de Frequência',
                Icon: 'fas fa-file-code',
                to: ''
            },
            {
                title: 'Uniforme Escolar',
                Icon: 'fas fa-user-tie',
                to: ''
            },
            {
                title: 'Publicação de Vagas',
                Icon: 'fas fa-user-tie',
                to: '/academic/publish'
            }
        ]
    },
    {
        title: 'Gestão de Frequência',
        to: undefined,
        mainIcon: 'fas fa-person-circle-check',
        subMenu: [
            {
                title: 'Presenças',
                Icon: 'fas fa-check',
                to: '/academic/school'
            },
            {
                title: 'Faltas',
                Icon: 'fas fa-remove',
                to: '/academic/school'
            },
            {
                title: 'Justificativo de Faltas',
                Icon: 'fas fa-user-cog',
                to: '/academic/school'
            },
        ]
    },
    {
        title: 'Relatórios e Estatísticos',
        to: undefined,
        mainIcon: 'fas fa-chart-bar',
        subMenu: [
            {
                title: 'Alunos Inscritos',
                Icon: 'fa fa-address-card',
                to: ''
            },
            {
                title: 'Alunos selecionados',
                Icon: 'fa fa-person-chalkboard',
                to: ''
            },
            {
                title: 'Lista de Funcionários',
                Icon: 'fa fa-user-shield',
                to: ''
            },
        ],
    },
    {
        title: 'Gestão Financeira',
        to: undefined,
        mainIcon: 'fas fa-headset',
        subMenu: [
            {
                title: 'Energia e Água',
                Icon: 'fa-solid fa-building',
                to: ''
            }
        ]
    },
    {
        title: 'Suporte Técnico',
        to: undefined,
        mainIcon: 'fas fa-headset',
        subMenu: [
            {
                title: 'Suporte Escolar',
                Icon: 'fas fa-phone',
                to: ''
            },
            {
                title: 'Suporte de Sistema',
                Icon: 'fas fa-tools',
                to: ''
            },
        ]
    },
]