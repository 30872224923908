import React, { useState } from 'react'
import { Box, Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export function ItemMenu({ mainIcon, title, to, subMenu = undefined }) {
    const [open, setOpen] = useState(false);
    const { palette } = useTheme()
    const resolvePath = useResolvedPath(to)
    const match = useMatch({ path: resolvePath.pathname, end: true })

    const handleClick = () => {
        if (subMenu && subMenu.length > 0) setOpen(oldValue => !oldValue);
    }

    return (
        <Box >
            {!to ?
                <>
                    <ListItem disablePadding color={palette.background.paper}>
                        <ListItemButton onClick={handleClick} sx={{ color: palette.background.paper }}>
                            <ListItemIcon color={palette.background.paper}>
                                <i className={`${mainIcon} sidebar-item-icon`} color={palette.background.paper}></i>
                            </ListItemIcon>
                            <ListItemText primary={title} id='font-item' sx={{ marginLeft: '-1rem', fontSize: '10px !importante', color: palette.background.paper }} />
                            {typeof subMenu !== 'undefined' && (open ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>
                    </ListItem>
                    {
                        subMenu &&
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {(subMenu && subMenu.length > 0) &&
                                    subMenu.map((item, id) => (
                                        <Link key={id} to={item.to} className='sidebar-item submenu'>
                                            <Typography textAlign={'justify'} color={palette.background.paper}><i className={`${item.Icon} sidebar-item-icon`} color={palette.background.paper}></i>{item.title}</Typography>
                                        </Link>
                                    ))
                                }
                            </List>
                        </Collapse>
                    }
                </> :
                <>
                    <ListItem disablePadding>
                        <Link to={to} className='sidebar-item' color={palette.background.paper}>
                            <Typography color={palette.background.paper}><i className={`${mainIcon} sidebar-item-icon`} color={palette.background.paper}></i>{title}</Typography>
                        </Link>
                    </ListItem>
                    {
                        subMenu &&
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {(subMenu && subMenu.length > 0) &&
                                    subMenu.map((el, id) => (
                                        <ListItemButton sx={{ pl: 4 }} key={id} >
                                            <ListItemIcon >
                                                <Icon color={palette.background.paper}>{el.Icon}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={el.title} sx={{color: palette.background.paper}}/>
                                        </ListItemButton>
                                    )
                                    )}
                            </List>
                        </Collapse>
                    }
                </>
            }
        </Box >
    )
}